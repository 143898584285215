import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";


const Data = [
    {
        countNum : 3000,
        countTitle: 'Полезная площадь склада',
    },
    {
        countNum : 1500,
        countTitle: 'Дополнительная открытая площадка',
    },
    {
        countNum : 150,
        countTitle: 'Площадь приемочной и отправочной экспедиции',
    },
    {
        countNum : 30,
        countTitle: 'Офисные помещения',
    },
];
const CounterUpSafe = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible &&
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} separator="," suffix="м<sup>2</sup>"/> : 0}</div>}

                        </TrackVisibility>
                        <h5 className="title">{data.countTitle}</h5>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpSafe;
