import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const HeroSafe = () => {
    return (
            <div className="rn-splite-style height-950">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="./images/herosafe/herosafe-1.jpg" alt="Склад" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="hero-inner">
                                    <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h1 className="title">Складская <span className="theme-gradient">Логистика</span> & Ответственное <span className="theme-gradient">Хранение</span></h1>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">
                                        В нынешних условиях Вам достаточно просто обратиться к нам с запросом, и мы разрешим его.<br />Личное присутствие не обязательно,<br />что сохранит Ваши силы и время.
                                    </p>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default btn-medium btn-icon" href="mailto:info@ucore.ru?subject=Коммерческий запрос">Арендовать сейчас</a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default HeroSafe
