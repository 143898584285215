import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const Data = [
    {
        title: "Возможность в кратчайшие сроки принять на хранение все виды грузов",
        description: "Komatsu грузоподъёмностью до 3,5 тонн и кран-балки грузоподъёмностью от 10 до 25 тонн. Вам не стоит беспокоиться о транспортировке груза на склад, всё это входит в наши услуги: мы забираем, грузим, выгружаем, размещаем, храним и отправляем ваш груз в необходимую точку. Таким образом, вы можете значительно сэкономить время на этих вопросах.",
        image: "howdiffersafe-1"
    },
    {
        title: "Более 4,000 м² складского хозяйства",
        description: "У нас работает специально обученный персонал с многолетним опытом вращения в данной сфере. Мы найдём подход к любому клиенту, так как знакомы со всеми нюансами и подводными камнями логистических процессов, и наш персонал очень ответственный. Территория охраняется 24/7, установлены системы видеонаблюдения, постоянно дежурит охрана. Мы всегда готовы вести с вами слаженный конструктивный диалог и давать постоянную обратную связь.",
        image: "howdiffersafe-2"
    },
    {
        title: "Склады оборудованы кран-балками грузо-подъемностью 10 и 25 тонн",
        description: "Наше помещение оборудовано всей специальной организационной техникой и программным обеспечением с выходом в интернет. Благодаря веб-соединению, у нас ведётся электронный документооборот, используются товароучётные системы для создания всей необходимой по операциям документации и исключения потери информации.",
        image: "howdiffersafe-3"
    },
]
const HowDifferSafeTwo = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {Data.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-5 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <h2 className="title">{data.title}</h2>
                                        <div>
                                            <p className="subtitle">{data.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-5 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/howdiffersafe/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default HowDifferSafeTwo;




















