import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} || Полный комплекс услуг по хранению и перевозке грузов</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="Предоставление услуги склада ответственного хранения грузов в городе Пермь. Полный комплекс услуг по хранению груза (упаковка, маркировка, комплектование), доступные тарифы и индивидуальные условия на логистические услуги для бизнеса." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="keywords" content="ответственное хранение грузов пермь упаковка маркировка комплектование доступно дешево условия бизнес склад холодный" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


