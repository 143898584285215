import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlipFour from "../elements/split/SlipFour";
import SlpitOne from "../elements/split/SlpitOne";
import AdvancePricingOne from "../elements/advancePricing/AdvancePricingOne";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import WhatIsContrainer from "../elements/container/WhatIsContrainer";
import UsefulSafe from "../elements/safe/UsefulSafe";
import CounterUpSafe from "../elements/safe/CounterSafe";
import HowDifferSafe from "../elements/safe/HowDifferSafe";

import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import ContactOne from "../elements/contact/ContactOne";
import HeroContainer from "../elements/container/HeroContainer";
import GoogleMapStyle from "../elements/contact/GoogleMapStyle";
import GalleryOne from "../elements/gallery/GalleryOne";
var BlogListData = BlogClassicData.slice(0, 3);

const PopupData2 = [
    {
        id: "01",
        image: "./images/gallery/gallery-1.jpg",
        popupLink: [
            './images/gallery/gallery-1.jpg',
        ],
    },
    {
        id: "02",
        image: "./images/gallery/gallery-2.jpg",
        popupLink: [
            './images/gallery/gallery-2.jpg',
        ],
    },
    {
        id: "03",
        image: "./images/gallery/gallery-3.jpg",
        popupLink: [
            './images/gallery/gallery-3.jpg',
        ],
    },
    {
        id: "04",
        image: "./images/gallery/gallery-4.jpg",
        popupLink: [
            './images/gallery/gallery-4.jpg',
        ],
    },
    {
        id: "05",
        image: "./images/gallery/gallery-5.jpg",
        popupLink: [
            './images/gallery/gallery-5.jpg',
        ],
    },
    {
        id: "06",
        image: "./images/gallery/gallery-6.jpg",
        popupLink: [
            './images/gallery/gallery-6.jpg',
        ],
    },
    {
        id: "07",
        image: "./images/gallery/gallery-7.jpg",
        popupLink: [
            './images/gallery/gallery-7.jpg',
        ],
    },
    {
        id: "08",
        image: "./images/gallery/gallery-8.jpg",
        popupLink: [
            './images/gallery/gallery-8.jpg',
        ],
    },
]

const Container = () => {
    return (
        <>
            <SEO title="Аренда контейнера" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <HeroContainer />
                {/* End Slider Area  */}

                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    title = "Склад-контейнер, что это?"
                                    description = "Ответхранение подразумевает согласованные правоотношения, основная суть которых —<br />подтверждение фиксации ваших пожеланий по хранению товара и гарантия его сохранности."
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <WhatIsContrainer />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                {/* Start Photos Area  */}
                <div className="rwt-gallery-area">
                    <div className="container">
                        <div className="row mt_dec--30 row--15">
                            {PopupData2.map((item) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Photos Area  */}

                {/* Start Contacts Area  */}
                {/*<div className="rwt-timeline-area rn-section-gap">*/}
                {/*    <div className="container">*/}
                {/*        <ContactOne />*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Contacts Area  */}

                {/* Start Map Area  */}
                <div className="rn-section-gap">
                    <div className="container">
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <GoogleMapStyle />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Map Area  */}

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Container
