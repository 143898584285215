import React from 'react'
import {Link} from "react-router-dom";
import {FaTelegram, FaWhatsapp, FaViber, FaEnvelope} from "react-icons/fa";

const Copyright = () => {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-7 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><a href="#">Пользовательское соглашение</a></li>
                                <li><a href="/contact">Контакты</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                <li><a target='_blank' href="https://t.me/+79223017001"><FaTelegram /></a></li>
                                <li><a target='_blank' href="https://wa.me/+79223017001"><FaWhatsapp /></a></li>
                                <li><a target='_blank' href="viber://chat?number=+79223017001"><FaViber /></a></li>
                                <li><a target='_blank' href="mailto:info@ucore.ru?subject=Коммерческий запрос"><FaEnvelope /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right text-center text-md-right">
                            <p className="copyright-text">© Юкор, {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Copyright;
