import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: '<path d="M24.0488 2C23.6621 1.98793 23.2856 2.12578 22.998 2.38476C22.998 2.38476 15.4902 9 6.5 9C6.10219 9.00004 5.72068 9.15809 5.43938 9.43938C5.15809 9.72068 5.00004 10.1022 5 10.5V22.7598C5 29.4378 8.08941 40.4264 23.418 46.8828C23.6022 46.9604 23.8001 47.0003 24 47.0003C24.1999 47.0003 24.3978 46.9604 24.582 46.8828C39.9106 40.4264 43 29.4378 43 22.7598V10.5C43 10.1022 42.8419 9.72068 42.5606 9.43938C42.2793 9.15809 41.8978 9.00004 41.5 9C32.5098 9 25.002 2.38476 25.002 2.38476C24.7395 2.14827 24.4019 2.01201 24.0488 2V2ZM24 5.42773C26.063 7.08636 32.1113 11.3372 40 11.875V22.7598C40 28.502 37.7934 37.7657 24 43.8262C10.2066 37.7657 8 28.502 8 22.7598V11.875C15.8887 11.3372 21.937 7.08636 24 5.42773ZM24 14C18.4949 14 14 18.4949 14 24C14 29.5051 18.4949 34 24 34C29.5051 34 34 29.5051 34 24C34 18.4949 29.5051 14 24 14ZM24 17C27.8838 17 31 20.1162 31 24C31 27.8838 27.8838 31 24 31C20.1162 31 17 27.8838 17 24C17 20.1162 20.1162 17 24 17ZM24.1699 20.0059C23.0909 19.9622 21.9924 20.3474 21.1699 21.1699C19.6099 22.7299 19.6099 25.2701 21.1699 26.8301C22.7299 28.3901 25.2701 28.3901 26.8301 26.8301C27.9901 25.6701 28.2907 23.9495 27.7207 22.5195C27.3707 23.1095 26.73 23.5 26 23.5C24.9 23.5 24 22.6 24 21.5C24 20.93 24.2409 20.4108 24.6309 20.0508C24.4784 20.027 24.3241 20.0121 24.1699 20.0059Z" z/>',
        title: 'Awarded Design',
        description: 'There are many variations variations  of passages of Lorem Ipsum available, but the majority have suffered.'
    },
    {
        icon: '<path d="M7.5 6C6.672 6 6 6.671 6 7.5V36.5C6 39.533 8.468 42 11.5 42H28.2617C27.6847 41.113 27.1965 40.109 26.8145 39H11.5C10.121 39 9 37.878 9 36.5V9H39V21.7598C39.145 21.8878 39.2834 22.0237 39.4004 22.1797C40.1404 23.1657 41.091 23.8028 42 24.2168V7.5C42 6.671 41.328 6 40.5 6H7.5ZM20.5 13C20.3012 12.9972 20.1039 13.0339 19.9194 13.108C19.735 13.1822 19.5671 13.2922 19.4255 13.4318C19.284 13.5713 19.1716 13.7376 19.0949 13.921C19.0181 14.1044 18.9786 14.3012 18.9786 14.5C18.9786 14.6988 19.0181 14.8956 19.0949 15.079C19.1716 15.2624 19.284 15.4287 19.4255 15.5682C19.5671 15.7078 19.735 15.8178 19.9194 15.892C20.1039 15.9661 20.3012 16.0028 20.5 16H27.5C27.6988 16.0028 27.8961 15.9661 28.0806 15.892C28.265 15.8178 28.4329 15.7078 28.5745 15.5682C28.716 15.4287 28.8284 15.2624 28.9051 15.079C28.9819 14.8956 29.0214 14.6988 29.0214 14.5C29.0214 14.3012 28.9819 14.1044 28.9051 13.921C28.8284 13.7376 28.716 13.5713 28.5745 13.4318C28.4329 13.2922 28.265 13.1822 28.0806 13.108C27.8961 13.0339 27.6988 12.9972 27.5 13H20.5ZM37 23C36.6943 23 36.3877 23.1269 36.1992 23.3789C33.5482 26.9129 29.042 26.9785 29 26.9785C28.448 26.9785 28 27.4265 28 27.9785V34C28 43.669 36.6708 45.9487 36.7578 45.9707C36.8378 45.9907 36.918 46 37 46C37.082 46 37.1622 45.9907 37.2422 45.9707C37.3292 45.9487 46 43.669 46 34V27.9785C46 27.4285 45.5539 26.9815 45.0039 26.9785C44.9589 26.9785 40.4518 26.9129 37.8008 23.3789C37.6123 23.1269 37.3057 23 37 23Z" />',
        title: 'Design & Creative',
        description: 'Passages there are many variations variations  of of Lorem Ipsum available, but the majority have suffered.'
    },
    {
        icon: <FiMap />,
        title: 'App Development',
        description: 'Variations There are many variations  of passages of Lorem Ipsum available, but the majority have suffered.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                <svg fill="currentColor" viewBox="0 0 48 48" height="48px" width="48px" xmlns="http://www.w3.org/2000/svg" dangerouslySetInnerHTML={{__html: val.icon}}></svg>
                                {/*{val.icon}*/}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;
