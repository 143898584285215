import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const WhySafeData = [
    {
        id: "1",
        date: "Удобство",
        order: "1",
        order2: "2",
        title: "Выдача груза клиентам по вашему поручению",
        description: "Ответственное хранение предусматривает услугу «Выдача груза, без присутствия клиента», чтобы вы могли потратить это время на куда более важные административные дела. Даже, если ваш офис находится в другом городе, ваш груз остается в целости и сохранности.",
        offer: "А ежемесячная инвентаризация, камеры видеонаблюдения и электронные платформенные весы — помогут избавиться от ненужных сомнений.",
        image: "whysafe-1",
    },
    {
        id: "2",
        date: "Сохранность",
        order: "2",
        order2: "1",
        title: "Полная материальная ответственность",
        description: "Организации, которые берут Ваши материальные ценности на хранение - несут полную ответственность за сохранность вашего груза.",
        offer: "Дополнительно к договорным обязанностям, мы оформили страховой полис на сумму 30 млн рублей, который предусматривает: кражу, физическое воздействие, неаккуратное обращение.",
        image: "whysafe-2"
    },
    {
        id: "3",
        date: "Безкомпромисность",
        order: "1",
        order2: "2",
        title: "Различные виды погрузо-разгрузочных работ",
        description: "Оказывая услугу ответственного хранения, компании должны иметь минимум для осуществления погрзо-разгрузочных работ, а именно: гидравлическая тележка, автомобильный погрузчик, квалифицированный персонал.",
        offer: "Мы имеем все способы выгрузки или погрузки вашего груза, а в этом нам помогают возможности склада, которые характеризуют его как складской терминал:" +
            "• Автомобильная рампа;" +
            "• Железнодорожная рампа;" +
            "• Автопогрузчики вилочные грузоподъемность до 2-х тонн;" +
            "• Кран - балки на 10 тонн и на 25 тонн, которые имеют возможность выгрузки полувагонов или платформ на железнодорожном тупике.",
        image: "whysafe-3"
    },
]

const WhySafe = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            {/* Start Single Timeline  */}

            {WhySafeData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className={`order-2 order-lg-${data.order} col-lg-6 mt_md--40 mt_sm--40`}>
                                    <div className="content">
                                        <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline theme-gradient">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <ScrollAnimation
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                            <h5 className="working-title">Что предлагаем</h5>
                                            <p className="description">{data.offer}</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                <div className={`order-1 order-lg-${data.order2} col-lg-6`}>
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/whysafe/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* End Single Timeline  */}
        </div>
    )
}

export default WhySafe;
